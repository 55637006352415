<div class="menu_dialog" [class.delete-trusted]="option.deleteTrusted">
  <div mat-dialog-title class="flex-end" *ngIf="!option.deleteTrusted">
    <button
      id="menu_{{ itemIndex }}-close-btn"
      mat-icon-button
      mat-dialog-close
      class="close-btn"
      [title]="'COMMON.BUTTON.DIALOG.CLOSE' | translate"
      [attr.aria-label]="'COMMON.BUTTON.DIALOG.CLOSE' | translate"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="menu__content">
      <pbb-notice
        *ngIf="!isErrorInline && data.errorKey"
        [title]="data.errorKey | translate"
        [inline]="isErrorInline"
        id="dialog_action_message"
        [type]="notice.ERROR"
        [showInContainer]="false"
      ></pbb-notice>
      <mat-icon class="confirm-icon" *ngIf="!option.showLogo && !option.deleteTrusted">{{ option.icon }}</mat-icon>
      <div *ngIf="option.showLogo" class="logo-container">
        <img
          *ngIf="(option && option.logo) || option.imageSrc; else showBankIcon"
          class="logo"
          [src]="(option.logo | base64 | async) || option.imageSrc"
          pbbImgError="assets/images/bank.png"
          alt="Bank Logo"
        />
        <ng-template #showBankIcon>
          <mat-icon class="logo confirm-logo" aria-hidden="true">account_balance</mat-icon>
        </ng-template>
      </div>
      <h1 class="mat-body-1 text-bold mb-4" id="menu_{{ option.action | lowercase }}_title">
        {{ getTranslation(option, 'confirmTitle') }}
      </h1>
      <span class="mat-body-2 text-grey-subtitle mb-24" id="menu_{{ option.action | lowercase }}_info">
        {{ getTranslation(option, 'confirmSubtitle') }}
      </span>
      <pbb-card *ngIf="option.deleteTrusted" class="card card_column card_normal" id="trusted-device-card">
        <mat-card-content>
          <div class="trusted-device-details">
            <span matListItemLine id="trusted-device-user-agent" class="mat-body-1 text-bold">
              {{ item.user_agent | uaParser }}
            </span>
            <span
              matListItemLine
              id="trusted-device-biometrics"
              class="mat-body-2 text-grey-subtitle"
              *ngIf="item.trusted !== null"
            >
              {{
                (item?.webauth_enabled
                  ? 'USER_PORTAL.MANAGE_TRUSTED_DEVICES.BIO_ENABLED'
                  : 'USER_PORTAL.MANAGE_TRUSTED_DEVICES.BIO_DISABLED'
                ) | translate
              }}
            </span>
            <span
              matListItemLine
              id="trusted-device-last-login-info"
              class="mat-body-2 text-grey-subtitle"
              *ngIf="item.trusted !== null"
            >
              {{ 'USER_PORTAL.TRUSTED_DEVICES.LAST_LOGGED_IN' | translate }}
              {{ item?.last_login_info?.timestamp | timeAgo }}
            </span>
            <mat-basic-chip
              matListItemLine
              class="device-chip mt-16"
              *ngIf="item.current"
              id="trusted-device-default-chip"
            >
              <mat-icon class="icon-sm text-black">check_circle</mat-icon>
              <span class="mat-caption text-info">{{
                'USER_PORTAL.MANAGE_TRUSTED_DEVICES.THIS_DEVICE' | translate
              }}</span>
            </mat-basic-chip>
          </div>
        </mat-card-content>
      </pbb-card>
      <pbb-notice
        *ngIf="isErrorInline && dialogMessageKey"
        [inline]="isErrorInline"
        [title]="
          dialogMessageKey
            | translate : (isSuccess ? option.dialogSuccessMessageParams : option.dialogErrorMessageParams)
        "
        id="dialog_action_message"
        [type]="isSuccess ? notice.INFO : notice.ERROR"
        [showInContainer]="false"
      ></pbb-notice>
      <div class="{{ option.deleteTrusted ? 'flex flex-end mt-24' : '' }}">
        <button
          *ngIf="option.deleteTrusted"
          mat-stroked-button
          mat-dialog-close
          class="btn-stroked-no-border mr-8"
          [color]="option.confirmCancelColor"
          id="menu_{{ option.action | lowercase }}_cancel-btn"
        >
          {{ isSuccess ? ('COMMON.BUTTON.CLOSE' | translate) : getTranslation(option, 'confirmCancel') }}
        </button>
        <button
          class="menu_content__unlink-confirm-btn"
          *ngIf="!!option.confirmButton"
          mat-flat-button
          cdkFocusInitial
          (click)="confirm(option)"
          [color]="option.confirmColor || 'primary'"
          id="menu_{{ option.action | lowercase }}_confirm-btn"
          [disabled]="isSuccess"
        >
          {{ getTranslation(option, 'confirmButton') }}
        </button>
        <button
          *ngIf="!!option.confirmCancel && !option.deleteTrusted"
          mat-stroked-button
          mat-dialog-close
          class="btn-stroked-no-border text-underlined"
          [color]="option.confirmCancelColor"
          id="menu_{{ option.action | lowercase }}_cancel-btn"
        >
          {{ isSuccess ? ('COMMON.BUTTON.CLOSE' | translate) : getTranslation(option, 'confirmCancel') }}
        </button>
      </div>
    </div>
  </div>
</div>
