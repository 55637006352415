import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr-ca';
import { STORED_LANGUAGE_KEY } from '@core/services/language.service';

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  constructor() {
    dayjs.extend(relativeTime);
  }
  transform(date?: string): string {
    const lang = localStorage.getItem(STORED_LANGUAGE_KEY) || '';
    if (date && dayjs(date).isValid()) {
      const currentLocale = lang ? JSON.parse(lang).toLowerCase() : 'en-ca';
      return dayjs(date).locale(currentLocale).fromNow();
    }
    return '';
  }
}
