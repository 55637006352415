<div
  class="payment-method-container"
  *ngFor="let linkedFi of userProfile?.linked_fi_info; index as i; let isLast = last"
>
  <div class="payment-method-container__fi mb-16">
    <pbb-address-suggestion
      id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_address_suggestion"
      [financialInstitution]="linkedFi"
      [hideSuggestion]="true"
    >
    </pbb-address-suggestion>
    <pbb-menu
      idLabel="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}"
      [options]="
        userProfile?.linked_fi_info?.length === 1
          ? [
              {
                action: Action.UNLINK_LAST,
                label: 'COMMON.PAYMENT_METHOD.FI.MENU.UNLINK',
                icon: 'link_off',
                showConfirm: true,
                confirmCancel: ['COMMON.BUTTON.CANCEL'],
                confirmTitle: ['COMMON.UNLINK_FI.UNLINK_BANK_LAST_FI'],
                confirmSubtitle: ['COMMON.UNLINK_FI.UNLINK_BANK_INFO_LAST_FI'],
                confirmButton: 'COMMON.UNLINK_FI.BUTTON.UNLINK_LAST_FI',
                confirmColor: 'warn',
                imageSrc: 'assets/images/warning.png',
                showLogo: true,
                closeOnSuccess: true
              }
            ]
          : [
              {
                action: Action.UNLINK,
                label: 'COMMON.PAYMENT_METHOD.FI.MENU.UNLINK',
                icon: 'link_off',
                showConfirm: true,
                confirmTitle: ['COMMON.UNLINK_FI.UNLINK_BANK', { bankName: linkedFi.display_name }],
                confirmSubtitle: ['COMMON.UNLINK_FI.UNLINK_BANK_INFO', { bankName: linkedFi.display_name }],
                confirmButton: 'COMMON.UNLINK_FI.BUTTON.UNLINK',
                confirmColor: 'warn',
                logo: linkedFi.logo,
                showLogo: true,
                closeOnSuccess: true,
                confirmCancel: ['COMMON.BUTTON.CANCEL'],
                dialogSuccessMessageParams: { displayName: linkedFi.display_name }
              }
            ]
      "
      [item]="linkedFi"
      [itemRef]="linkedFi.linked_fi_id"
      [menuAriaLabel]="linkedFi.display_name"
      [itemIndex]="i"
      (menuAction)="menuAction.emit($event)"
    ></pbb-menu>
  </div>
  <div id="fi_no_available_account_message_{{ i }}" class="flex-row flex-start" *ngIf="!linkedFi.accounts?.length">
    <mat-icon class="icon-smd mr-12 material-icons-outlined text-warn" id="help_icon">gpp_maybe</mat-icon>
    <span class="mat-headline-4">{{ 'FI.ACCOUNT.NO_AVAILABLE.MESSAGE' | translate }}</span>
  </div>
  <mat-list class="payment-method-container__fi-account-list" id="{{ linkedFi.display_name | idFormat }}_account_list">
    <mat-list-item
      class="payment-method-container__fi-account-list-item"
      id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{ account.display_name_en | idFormat }}_{{
        account.account_last_digits
      }}"
      *ngFor="let account of linkedFi.accounts"
    >
      <div class="payment-method-container__fi-account">
        <div class="flex-row">
          <div class="payment-method-logo">
            <pbb-svg-icon
              id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                account.display_name_en | idFormat
              }}_{{ account.account_last_digits }}_logo"
              alt=""
              width="38px"
              height="38px"
              [icon]="account.accountLogo || ''"
            ></pbb-svg-icon>
          </div>
          <div class="flex-col flex-start">
            <span
              class="payment-method-display-name break-anywhere"
              id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                account.display_name_en | idFormat
              }}_{{ account.account_last_digits }}_text"
              >{{ account.displayName || '' | translate }}</span
            >
            <span
              class="payment-method-type break-anywhere"
              id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                account.display_name_en | idFormat
              }}_{{ account.account_last_digits }}_type_digits"
              >{{ account.accountTypeKey || '' | translate }} ({{ 'FI.ACCOUNT.ENDING_IN' | translate }}
              {{ account.account_last_digits }})</span
            >
            <mat-chip-listbox
              *ngIf="userProfile?.default_fi_account_ref === account.fi_account_ref"
              [attr.aria-label]="'ARIA_LABEL.PAYMENT_METHOD_FLAGS' | translate"
            >
              <mat-basic-chip
                [attr.aria-label]="'ARIA_LABEL.DEFAULT_PAYMENT_METHOD' | translate"
                class="default-chip primary-chip"
                id="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
                  account.display_name_en | idFormat
                }}_{{ account.account_last_digits }}_default_chip"
              >
                <mat-icon aria-hidden="true">check_circle</mat-icon
                ><span class="mat-caption">{{ 'COMMON.DEFAULT' | translate }}</span>
              </mat-basic-chip>
            </mat-chip-listbox>
          </div>
        </div>
      </div>
      <pbb-menu
        idLabel="{{ linkedFi.display_name | idFormat }}_{{ linkedFi.fi_user_id }}_{{
          account.display_name_en | idFormat
        }}_{{ account.account_last_digits }}"
        [options]="[
          {
            action: Action.DEFAULT,
            label: 'COMMON.PAYMENT_METHOD.SET_DEFAULT_PAYMENT',
            icon: 'check',
            showConfirm: false,
            disabled: userProfile?.default_fi_account_ref === account.fi_account_ref
          }
        ]"
        [item]="account"
        [itemRef]="account.fi_account_ref"
        [menuAriaLabel]="linkedFi?.display_name + ' ' + account.displayName"
        [itemIndex]="i"
        (menuAction)="setPaymentMethod($event, linkedFi.linked_fi_id)"
      ></pbb-menu>
    </mat-list-item>
  </mat-list>
  <hr *ngIf="!isLast" class="mt-24 mb-24" />
</div>
