<button
  mat-icon-button
  *ngIf="!isMobile; else showMobileButton"
  [matMenuTriggerFor]="menu"
  id="{{ idLabel }}-menu-more-options-btn"
  class="btn-icon-slim"
  [attr.aria-label]="'ARIA_LABEL.ADDRESS.MENU.MORE_OPTIONS' | translate: { for: menuAriaLabel }"
  [attr.aria-description]="'ARIA_DESC.ADDRESS.MENU.MORE_OPTIONS' | translate"
  [disabled]="disabled"
>
  <mat-icon>more_vert</mat-icon>
</button>
<ng-template #showMobileButton>
  <button
    mat-icon-button
    (click)="openMobileMenu()"
    id="{{ idLabel }}-menu-more-options-btn"
    class="btn-icon-slim"
    [attr.aria-label]="'ARIA_LABEL.ADDRESS.MENU.MORE_OPTIONS' | translate: { for: menuAriaLabel }"
    [attr.aria-description]="'ARIA_DESC.ADDRESS.MENU.MORE_OPTIONS' | translate"
    [disabled]="disabled"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-template>

<mat-menu #menu="matMenu">
  <div *ngFor="let option of options; last as isLast">
    <button
      mat-menu-item
      (click)="openDesktopMenu(option)"
      [disabled]="
        (option.disabled | typeOf) === 'function' ? option && $any(option?.disabled)(item, itemIndex) : option.disabled
      "
      id="menu-context-{{ itemIndex }}-{{ option.label | lowercase }}-btn"
      [attr.aria-label]="option.label | translate"
      [style.color]="option.color || undefined"
    >
      <mat-icon aria-hidden="true"
      [style.color]="option.color || undefined">
        {{ option.icon }}
      </mat-icon>
      {{ option.label | translate }}
    </button>
    <hr *ngIf="!isLast" class="hr-menu" />
  </div>
</mat-menu>
