import { Pipe, PipeTransform } from '@angular/core';
import { UAParser } from 'ua-parser-js';

@Pipe({
  name: 'uaParser',
  standalone: true,
})
export class UaParserPipe implements PipeTransform {
  transform(value: string): string {
    const deviceDetail = new UAParser().setUA(value).getResult();
    return `${deviceDetail?.browser?.name || ''} ${deviceDetail?.device?.vendor || ''} ${
      deviceDetail?.os?.name || ''
    } ${deviceDetail?.os?.version || ''} `.trim();
  }
}
